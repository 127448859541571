import { Injectable } from '@angular/core';
import AppExtensionsSDK, {
  Command,
} from '@pipedrive/app-extensions-sdk';
import { Params } from '@angular/router';
import { Deal } from './pipedrive.model';
import { API_URL } from '../../shared/service/api.constant';
import { ApiService } from '../../core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class PipedriveService {
  private sdk: AppExtensionsSDK;
  private initialized = false;

  constructor(private http: ApiService) {}

  get isInitialized() {
    return this.initialized;
  }

  async initialize() {
    this.sdk = new AppExtensionsSDK();
    await this.sdk.initialize();
    this.initialized = true;
  }

  async resizeModal(width: number, height: number) {
    await this.sdk.execute(Command.RESIZE, { width, height });
  }

  async closeModal() {
    await this.sdk.execute(Command.CLOSE_MODAL);
  }

  async showSnackbar(message: string) {
    await this.sdk.execute(Command.SHOW_SNACKBAR, { message });
  }

  getDeal(id: number, params?: Params) {
    return this.http.get<Deal>(
      API_URL.pipedrive_deals + `${id}/`,
      params,
    );
  }

  createPeakContact(data: any) {
    // return this.http.post(ApiUrl.peak.contacts, data, {
    //   headers: this.getAuthHeaders(),
    // });
  }
}
