import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  static departmentTreeResponse = null;

  constructor(private http: ApiService) {}

  static clearDepartmentTreeCache() {
    DepartmentService.departmentTreeResponse = null;
  }

  getDepartmentList(data?, header?) {
    return this.http.get(API_URL.departments, data, header);
  }

  addDepartment(data) {
    return this.http.post(API_URL.departments, data);
  }

  deleteDepartment(id) {
    return this.http.delete(API_URL.departments + id + '/');
  }

  editDepartment(id, data) {
    return this.http.patch(API_URL.departments + id + '/', data);
  }

  getDepartment(id) {
    return this.http.get(API_URL.departments + id + '/');
  }

  getDepartmentLevelList(data?) {
    return this.http.get(API_URL.department_level, data);
  }

  addDepartmentLevel(data) {
    return this.http.post(API_URL.department_level, data);
  }

  deleteDepartmentLevel(id) {
    return this.http.delete(API_URL.department_level + id + '/');
  }

  editDepartmentLevel(id, data) {
    return this.http.patch(API_URL.department_level + id + '/', data);
  }
  getDepartmentLevelId(id) {
    return this.http.get(API_URL.department_level + id + '/');
  }

  getDepartmentTree(data?, header?) {
    return new Observable((subscriber) => {
      const isUseCatch = !data || data.is_all; // only use catch when get all department
      if (isUseCatch && DepartmentService.departmentTreeResponse) {
        subscriber.next(
          _.cloneDeep(DepartmentService.departmentTreeResponse),
        );
        return;
      }
      this.http.get(API_URL.departments_tree, data, header).subscribe(
        (response) => {
          if (isUseCatch) {
            DepartmentService.departmentTreeResponse = response;
          }
          subscriber.next(response);
        },
        (error) => {
          subscriber.error(error);
        },
      );
    });
  }

  batchUploadFile(data, type) {
    return this.http.post(`/api/batch-uploads/${type}/`, data);
  }

  getAllPeople(params?) {
    return this.http.get(API_URL.people + 'all/', params);
  }

  getDepartmentCSV(params?): Observable<any> {
    return this.http.getBlob(API_URL.departments + 'excel/', params);
  }

  getDepartmentMemberConfig(params?): Observable<any> {
    return this.http.get(API_URL.department_member_config, params);
  }

  editDepartmentMemberConfig(id, data): Observable<any> {
    return this.http.patch(
      API_URL.department_member_config + id + '/?all_config=true',
      data,
    );
  }
}
