import {
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pv-approval-confirmation',
  templateUrl: './pv-approval-confirmation.component.html',
  styleUrls: ['./pv-approval-confirmation.component.scss'],
})
export class PVApprovalConfirmationModalComponent {
  @Output() approve = new EventEmitter();

  @ViewChild('confirmationModal', { static: false })
  confirmationModal: ElementRef;
  confirmationModalRef: NgbModalRef;

  constructor(public modalService: NgbModal) {}

  onClickApprove(): void {
    this.modalService.dismissAll();
    this.approve.emit();
  }

  openConfirmationModal(center = true): void {
    this.confirmationModalRef = this.modalService.open(
      this.confirmationModal,
      {
        backdrop: 'static',
        centered: center,
      },
    );
  }
}
