import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownItem } from '../../models/common.model';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnChanges {
  @Input() minuteStep = 15;
  @Input() startTime: string;
  @Input() minTime = '00:00';
  @Input() excludeMinTime = false;

  @Input()
  set value(val: string) {
    this._value = val;
  }

  get value(): string {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<string>();

  timeList: DropdownItem<string>[] = [];
  _value: string;

  constructor() {}

  ngOnInit(): void {
    this.setTimeList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTimeList();
  }

  getDurationString(startTime: string, endTime: string): string {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    const endTotal = endHour * 60 + endMinute;
    const startTotal = startHour * 60 + startMinute;
    const diffMinutes = endTotal - startTotal;

    return String(diffMinutes / 60);
  }

  setTimeList(): void {
    const [minHour, minMinute] = this.minTime.split(':').map(Number);

    const result: DropdownItem<string>[] = [];
    for (let i = minHour; i <= 23; i++) {
      let j = i === minHour ? minMinute : 0;
      for (; j <= 59; j += this.minuteStep) {
        const value =
          String(i).padStart(2, '0') +
          ':' +
          String(j).padStart(2, '0');

        result.push({
          label: value,
          value,
          context: {
            duration: this.startTime
              ? this.getDurationString(this.startTime, value)
              : null,
          },
        });
      }
    }

    if (this.excludeMinTime) {
      result.splice(0, 1);
    }

    this.timeList = result;
  }

  onKeyPress(event: KeyboardEvent): boolean {
    const key = event.key;
    if (!key.match(/^([0-9]|:|Backspace|Tab|Enter)$/)) {
      event.stopPropagation();
      return false;
    }
  }

  customSearchItem(term: string, item): boolean {
    const value = item.value.replace(':', '');
    return (
      value.startsWith(term) ||
      (value.charAt(0) === '0' && value.substring(1).startsWith(term))
    );
  }
}
