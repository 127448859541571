import {
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sufficient-warning',
  templateUrl: './sufficient-warning.component.html',
  styleUrls: ['./sufficient-warning.component.scss'],
})
export class SufficientWarningModalComponent {
  @Output() confirm = new EventEmitter();

  @ViewChild('warningModal', { static: false })
  warningModal: ElementRef;
  warningModalRef: NgbModalRef;

  constructor(public modalService: NgbModal) {}

  onClick(): void {
    this.confirm.emit();
  }

  openWarningModal(center = true): void {
    this.warningModalRef = this.modalService.open(this.warningModal, {
      backdrop: 'static',
      centered: center,
    });
  }
}
