import {
  Component,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pv-generation-notification',
  templateUrl: './pv-generation-notification.component.html',
  styleUrls: ['./pv-generation-notification.component.scss'],
})
export class PVGenerationNotificationModalComponent {
  @Input() payment_vouchers;

  @ViewChild('notificationModal', { static: false })
  notificationModal: ElementRef;
  notificationModalRef: NgbModalRef;

  constructor(
    private router: Router,
    public modalService: NgbModal,
  ) {}

  goToGeneratedPV(): void {
    const latestPaymentVoucher =
      this.payment_vouchers[this.payment_vouchers.length - 1];
    this.goToMemoDetail(latestPaymentVoucher.memo_id);
  }

  goToMemoDetail(id: number): void {
    this.router.navigate(['memos', 'preview', id]).then(() => {
      window.location.reload();
    });
  }

  openNotificationModal(center = true): void {
    this.notificationModalRef = this.modalService.open(
      this.notificationModal,
      {
        backdrop: 'static',
        centered: center,
      },
    );
  }

  close() {
    this.modalService.dismissAll();
  }
}
