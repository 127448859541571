import { Injectable } from '@angular/core';
import { ApiService } from '../../core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';
import { Observable } from 'rxjs';
import {
  OTRequestMonthlyStatus,
  ReadOTRequest,
} from './shared/ot-request.models';
import { DrfResponse } from '../../shared/models/drf-response.models';

@Injectable({ providedIn: 'root' })
export class OTRequestService {
  constructor(private http: ApiService) {}

  addOTRequest(data) {
    return this.http.post(API_URL.ot_request, data);
  }

  approveOTRequestList(data) {
    return this.http.patch(API_URL.ot_request + 'approve/', data);
  }

  deleteOTRequest(id: number) {
    return this.http.delete(API_URL.ot_request + id + '/');
  }

  getOTRequestList(params?): Observable<DrfResponse<ReadOTRequest>> {
    return this.http.get(API_URL.ot_request, params);
  }

  getOTRequestReportList(
    params?,
  ): Observable<DrfResponse<ReadOTRequest>> {
    return this.http.get(API_URL.ot_request_report, params);
  }

  rejectOTRequestList(data) {
    return this.http.patch(API_URL.ot_request + 'reject/', data);
  }

  getMyTaskBadge(params?) {
    return this.http.get(API_URL.ot_request + 'badge/', params);
  }

  getOTRequestMonthlyStatus(
    month: number,
    year: number,
  ): Observable<OTRequestMonthlyStatus[]> {
    return this.http.get(
      API_URL.ot_request_me + `monthly-status/${year}/${month}/`,
    );
  }
}
