<ng-template #transferringModal>
  <app-modal-confirmation
    [title]="'E-WALLET.TRANSFERRING-CONFIRMATION'"
    [btnConfirm]="'E-WALLET.TRANSFER'"
    [btnCancel]="'E-WALLET.LATER'"
    (confirmModal)="onClickTransfer()"
    (closeModal)="onClickLater()">
    <ng-template appCustomModalBody>
      <div class="modal-body text-center">
        <div class="f-18 mb-2">{{ "E-WALLET.Confirm to transfer?" | translate }}</div>
        <div *ngIf="showWarning" class="text-red">
          {{ "E-WALLET.You can proceed to transfer the approved amount later in Memo" | translate }}
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
