import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';
import { PipedriveService } from '../../modules/pipedrive/pipedrive.service';

@Injectable({
  providedIn: 'root',
})
export class PipedriveGuard implements CanActivateChild {
  constructor(private pipedriveService: PipedriveService) {}

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    if (
      childRoute.queryParamMap.has('token') &&
      !this.pipedriveService.isInitialized
    ) {
      await this.pipedriveService.initialize();
      await this.pipedriveService.resizeModal(400, 400);
    }
    return true;
  }
}
