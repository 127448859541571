import { Component, Input } from '@angular/core';
import * as moment from 'moment';

const dowColors = {
  1: '#F0AD4E',
  2: '#FF007F',
  3: '#5CB85C',
  4: '#FE4E03',
  5: '#5BC0DE',
  6: '#9933CC',
  7: '#D9534F',
};

@Component({
  selector: 'app-day-of-week-badge',
  templateUrl: './day-of-week-badge.component.html',
  styleUrls: ['./day-of-week-badge.component.scss'],
})
export class DayOfWeekBadgeComponent {
  @Input() date: string;

  constructor() {}

  get dateObj(): moment.Moment {
    return moment(this.date);
  }

  get dateDisplay(): string {
    return this.dateObj.format('ddd').toUpperCase();
  }

  get color(): string {
    return dowColors[this.dateObj.isoWeekday()];
  }
}
