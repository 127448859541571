<ng-template #warningModal>
  <app-modal-confirmation
    [hasHeader]="false">
    <ng-template appCustomModalBody>
      <div class="modal-body text-center">
        <span class="f-18 font-weight-bold mb-2 text-red">{{ "E-WALLET.Warning!" | translate }}</span>
        <div>{{ "E-WALLET.You can proceed to transfer the approved amount later in Memo" | translate }}</div>
      </div>
    </ng-template>
    <ng-template appCustomModalFooter>
      <div class="modal-footer">
        <button class="btn btn-confirm"
          type="button"
          appSetTheme
          (click)="onClick()">{{ "E-WALLET.OK" | translate }}</button>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
