import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mark-as-paid',
  templateUrl: 'mark-as-paid.component.html',
  styleUrls: ['./mark-as-paid.component.scss'],
})
export class MarkAsPaidModalComponent {
  @Output() confirm = new EventEmitter();

  @ViewChild('markAsPaidModal', { static: false })
  markAsPaidModal: ElementRef;
  markAsPaidModalRef: NgbModalRef;

  constructor(public modalService: NgbModal) {}

  onClickConfirm() {
    this.confirm.emit();
  }

  openMarkAsPaidModal(center = true): void {
    this.markAsPaidModalRef = this.modalService.open(
      this.markAsPaidModal,
      {
        backdrop: 'static',
        centered: center,
      },
    );
  }
}
