import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transferring-confirmation',
  templateUrl: './transferring-confirmation.component.html',
  styleUrls: ['./transferring-confirmation.component.scss'],
})
export class TransferringConfirmationModalComponent {
  @Input() showWarning = false;
  @Output() transfer = new EventEmitter();
  @Output() later = new EventEmitter();

  @ViewChild('transferringModal', { static: false })
  transferringModal: ElementRef;
  transferringModalRef: NgbModalRef;

  oneTimeSigFileName = '';
  constructor(public modalService: NgbModal) {}

  onClickTransfer(): void {
    this.modalService.dismissAll();
    this.transfer.emit(this.oneTimeSigFileName);
  }

  onClickLater(): void {
    this.modalService.dismissAll();
    this.later.emit(this.oneTimeSigFileName);
  }

  openTransferringModal(
    oneTimeSigUploadOriginalFileName = '',
    center = true,
  ): void {
    this.oneTimeSigFileName = oneTimeSigUploadOriginalFileName;
    this.transferringModalRef = this.modalService.open(
      this.transferringModal,
      {
        backdrop: 'static',
        centered: center,
      },
    );
  }
}
