<ng-template #markAsPaidModal>
  <app-modal-confirmation
    [title]="'E-WALLET.MARK-AS-PAID'"
    [btnConfirm]="'E-WALLET.MARK-AS-PAID'"
    [btnCancel]="'E-WALLET.CANCEL'"
    (confirmModal)="onClickConfirm()">
    <ng-template appCustomModalBody>
      <div class="modal-body text-center">
        <div class="f-18">
          <span>{{ "E-WALLET.Confirm to" | translate }} </span>
          <span class="font-weight-bold">{{ "E-WALLET.Mark as Paid" | translate }}</span>?
        </div>
        <span class="mb-2 text-red">{{ "E-WALLET.This action can't be undone!" | translate }}</span>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
