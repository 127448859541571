<ng-select [items]="timeList"
  bindValue="value"
  [clearable]="false"
  (keydown)="onKeyPress($event)"
  appendTo="body"
  [ngModel]="value"
  (ngModelChange)="valueChange.emit($event)"
  [maxlength]="5"
  [searchFn]="customSearchItem">
  <ng-template ng-option-tmp let-item="item">
    {{ item.label }}
    <small *ngIf="item.context.duration">
      ({{ item.context.duration }} {{ 'TIMESHEET.HR' | translate }})
    </small>
  </ng-template>
</ng-select>
